import { modalOptionsSelector } from 'data/selectors/modals';
import { editorServicesList } from 'helpers/services/editorServicesList';

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Analytics } from 'services/analytics';
import { EModalTypes } from 'ts/enums/modal.types';

import closeImagePath from 'assets/close-icon.svg';
import CommonButton from 'components/common/button';
import { toggleModal } from 'data/actions/modals';

import { EServicesKey } from 'helpers/services/types';
import useFileUploadAndCompress from 'hooks/useFileUploadAndCompress';
import useEditorFlowFileUploadAndCompress, {
  COMPRESSED_SIZE_KEY,
} from 'hooks/editor-flow/useFileUploadAndCompress';
import { useDispatch } from 'react-redux';
import { getCompressedSize } from 'utils/getCompressedSize';
import ModalLayout from '../baseModal';
import { CloseIcon } from '../baseModal/styles';
import { CompressionLevel, CompressionLevelsList } from './compressionLevels';
import { Container, FileInfoContainer, OptionsList, Title } from './style';

export const ChooseCompressionLevelModal = () => {
  const { file } = useSelector(modalOptionsSelector);
  const { t } = useTranslation();
  const [currentCompressionLevel, setCurrentCompressionLevel] = useState<CompressionLevel>('high');
  const dispatch = useDispatch();

  const handleSetCompressionLevel = (level: CompressionLevel) => {
    setCurrentCompressionLevel(level);
    Analytics.sendEvent({
      event: 'compress_select_type_tap',
      data: { type: level },
    });
  };

  const { handleUploadFile: handleUploadImageFile } = useFileUploadAndCompress({
    service: editorServicesList(t).find((service) => service.key === EServicesKey.COMPRESS_IMAGES),
  });

  const { handleUploadFile: handleUploadPdfFile } = useEditorFlowFileUploadAndCompress({
    service: editorServicesList(t).find((service) => service.key === EServicesKey.COMPRESS_PDF),
    modalType: EModalTypes.PROGRESS_EDIT_FILE,
  });

  useEffect(() => {
    Analytics.sendEvent({
      event: 'file_compress_modal_view',
    });
  }, []);

  const onClosePopup = () => {
    Analytics.sendEvent({
      event: 'compress_select_type_close_tap',
    });
    dispatch(toggleModal({ visible: false }));
  };

  const formatOriginalSize = (fileSizeBytes: number) => {
    if (fileSizeBytes <= 0) return '0 KB';

    const oneMB = 1024 * 1024;
    const fileSizeMB = fileSizeBytes / oneMB || 0;

    if (fileSizeMB >= 1) {
      return `${fileSizeMB.toFixed(2)} MB`;
    } else {
      const fileSizeKB = fileSizeBytes / 1024;
      return `${fileSizeKB.toFixed(2)} KB`;
    }
  };

  const handleConfirm = () => {
    if (currentCompressionLevel !== null) {
      file.type === 'application/pdf' ? handleUploadPdfFile(file) : handleUploadImageFile(file)
    }

    Analytics.sendEvent({
      event: 'compres_select_type_confirm_tap',
      data: { type: currentCompressionLevel },
    });

    const { compressedSize, percentageDecrease, sizeUnit } = getCompressedSize(
      file?.size,
      currentCompressionLevel
    );
    localStorage.setItem(
      COMPRESSED_SIZE_KEY,
      JSON.stringify({
        compressedSize: compressedSize,
        percentage: percentageDecrease,
        originalSize: formatOriginalSize(file?.size),
        sizeUnit: sizeUnit,
      })
    );
  };

  const renderFileName = useMemo(() => {
    if (!file?.name) return '';

    const [name, extension = 'pdf'] = file.name.split(/\.(?=[^.]+$)/);
    return name.length > 20 ? `${name.slice(0, 21)}...${extension}` : file.name;
  }, [file]);

  const getTitle = () => {
    return ['image/png', 'image/jpeg', 'image/heic'].includes(file?.type)
      ? t('select_compression_level_modal.image_title')
      : t('select_compression_level_modal.title');
  };

  return (
    <ModalLayout>
      <Container data-testid="choose-compression-level-modal">
        <CloseIcon data-testid="close-choose-compression-level-modal" onClick={onClosePopup} src={closeImagePath} alt="" />
        <Title>{getTitle()}</Title>

        <FileInfoContainer>
          <span>{renderFileName}</span>
          <span>{formatOriginalSize(file?.size)}</span>
        </FileInfoContainer>

        <OptionsList>
          <span>{t('select_compression_level_modal.options_label')}</span>
          <CompressionLevelsList
            file={file}
            currentCompressionLevel={currentCompressionLevel}
            handleSetCompressionLevel={handleSetCompressionLevel}
          />
        </OptionsList>

        <CommonButton
          data-testid="choose-compression-level-continue-button"
          onClick={handleConfirm}
          type="primary"
          disabled={!file?.name || currentCompressionLevel === null}
        >
          {t('select_compression_level_modal.button_text')}
        </CommonButton>
      </Container>
    </ModalLayout>
  );
};

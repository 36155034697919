import radioButtonCheckPath from 'assets/radiobutton-check.svg';
import radioButtonPath from 'assets/radiobutton.svg';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getCompressedSize } from 'utils/getCompressedSize';

import {
  AdditionalInfoContainer,
  CheckboxContainer,
  CompressionLevelItemContainer,
  CompressionLevelTitle,
  ContentContainer,
  FinalSizeContainer,
  MainInfoContainer,
} from './style';

export type CompressionLevel = 'high' | 'medium' | 'low';

export interface ICompressList {
  title: string;
  description: string;
  id: CompressionLevel;
}

interface CompressionLevelsListProps {
  file: File;
  currentCompressionLevel: CompressionLevel;
  handleSetCompressionLevel: (levelId: CompressionLevel) => void;
}

interface CompressionLevelItemProps {
  item: ICompressList;
  fileSize: number;
  isSelected: boolean;
  onSelect: (level: CompressionLevel) => void;
  t: (key: string) => string;
}

const CompressionLevelItem = memo(
  ({ item, fileSize, isSelected, onSelect, t }: CompressionLevelItemProps) => {
    const handleClick = useCallback(() => {
      onSelect(item.id);
    }, [onSelect, item.id]);

    const { compressedSize, sizeUnit } = getCompressedSize(fileSize, item.id);

    return (
      <CompressionLevelItemContainer data-testid={`modal-format-item-${item.id}`} onClick={handleClick} current={isSelected?.toString()}>
        <CheckboxContainer>
          <img src={isSelected ? radioButtonCheckPath : radioButtonPath} alt="check" />
        </CheckboxContainer>
        <ContentContainer>
          <MainInfoContainer>
            <CompressionLevelTitle>{item.title}</CompressionLevelTitle>
            <FinalSizeContainer>
              {t('select_compression_level_modal.final_size')}{' '}
              <b>
                ~{compressedSize}
                {sizeUnit}
              </b>
            </FinalSizeContainer>
          </MainInfoContainer>
          <AdditionalInfoContainer>{item.description}</AdditionalInfoContainer>
        </ContentContainer>
      </CompressionLevelItemContainer>
    );
  }
);

export const CompressionLevelsList = ({
  file,
  currentCompressionLevel,
  handleSetCompressionLevel,
}: CompressionLevelsListProps) => {
  const { t } = useTranslation();
  const fileSizeBytes = file?.size ?? 0;

  const compressList: ICompressList[] = useMemo(
    () => [
      {
        title: t('select_compression_level_modal.options.0.title'),
        description: t('select_compression_level_modal.options.0.description'),
        id: 'high',
      },
      {
        title: t('select_compression_level_modal.options.1.title'),
        description: t('select_compression_level_modal.options.1.description'),
        id: 'medium',
      },
      {
        title: t('select_compression_level_modal.options.2.title'),
        description: t('select_compression_level_modal.options.2.description'),
        id: 'low',
      },
    ],
    [t]
  );

  return (
    <>
      {compressList.map((item) => (
        <CompressionLevelItem
          key={`modal-format-item-${item.id}`}
          item={item}
          fileSize={fileSizeBytes}
          isSelected={currentCompressionLevel === item.id}
          onSelect={handleSetCompressionLevel}
          t={t}
        />
      ))}
    </>
  );
};

import styled from 'styled-components';

export const CompressionLevelItemContainer = styled.div<{ current?: string }>`
  display: flex;
  flex-direction: row;

  min-width: 205px;
  width: 100%;
  padding: var(--Radius-s, 10px);
  gap: 10px;
  border-radius: var(--Radius-xs, 5px);
  outline: ${(props) => (props?.current === 'true' ? '2px solid #3758F9' : '1px solid #c9d8f4')};
  padding: 10px;
  cursor: pointer;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const MainInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: space-between;
`;

export const AdditionalInfoContainer = styled.div`
  width: 100%;
  color: var(--Text-color-subtitle, #4c5e7f);
  font-family: Verdana;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;

export const CompressionLevelTitle = styled.div`
  color: var(--Text-color-tittle, #212e45);
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 19.2px */
`;

export const FinalSizeContainer = styled.div`
  color: var(--Basic-main-text, #1d1d1d);
  font-family: Arial;
  font-size: 16px;
  line-height: 130%; /* 20.8px */
`;

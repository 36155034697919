import styled from 'styled-components';
import { Button } from 'react-scroll';

export const Container = styled.div<{ background?: string }>`
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: ${(props) => (props?.background ? `url(${props?.background}) no-repeat` : 'unset')};
  background-size: cover;
  border-radius: 10px;

  @media (max-width: 760px) {
    margin: 0;
    border-radius: 0;
  }

  @media (min-width: 1600px) {
    height: calc(100vh - 40px);
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 800px;
  max-width: 1400px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px;

  @media (max-width: 760px) {
    min-height: 343px;
    padding: 24px 16px;
    gap: 0;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

export const Logo = styled.img`
  width: 159px;
  height: auto;
  cursor: pointer;

  @media (max-width: 760px) {
    width: 124px;
    height: auto;
  }
`;

export const CaptionContainer = styled.div`
  display: flex;
  height: 26px;
  justify-content: end;
  width: 100%;

  @media (max-width: 760px) {
    height: 18px;
    justify-content: center;
  }
`;

export const Caption = styled.span`
  color: var(--Blue-10, #ebeeff);
  font-size: 16px;

  @media (max-width: 760px) {
    font-weight: 400;
    font-size: 13px;
  }
`;

export const HeroMainContent = styled.div`
  text-align: center;
  max-width: 960px;
  padding: 72px 0;

  @media (max-width: 760px) {
    padding: 0;
    max-width: 343px;
    margin-top: 37px;
    margin-bottom: 52px;
  }
`;

export const HeroHeaderLayout = styled.div`
  flex: 1 0 0;
  margin-bottom: 20px;
  @media (max-width: 760px) {
    margin-bottom: 12px;
  }
`;

export const HeroHeader = styled.h1`
  color: var(--White-White, #fff);
  text-align: center;
  line-height: 120%;
  font-size: 3rem;
  font-weight: 900;

  @media (max-width: 760px) {
    font-size: 28px;
  }

  @media (max-width: 350px) {
    font-size: 24px;
  }
`;

export const HeroSecondaryHeader = styled.h5`
  color: var(--White-White, #fff);
  font-size: 28px;
  text-align: center;
  align-self: stretch;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 80px;
  font-weight: 900;
  height: 37px;

  @media (max-width: 760px) {
    font-size: 19px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
    margin-bottom: 30px;
    height: 24px;
  }
`;

export const HeroButton = styled(Button)`
  font-family: Arial, sans-serif;
  padding: 20px 40px;
  max-width: 350px;
  height: 70px;
  min-width: 180px;
  border-radius: 5px;
  background: var(--Primary-Color, #3758f9);
  color: var(--White-White, #fff);
  border: unset;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  cursor: pointer;

  &:hover {
    background: var(--background-button-primary-hover, #2443d7);
  }

  svg {
    color: #fff !important;
  }

  @media (max-width: 760px) {
    height: 54px;
    min-width: 140px;
    padding: 15px 30px;
    max-width: 158px;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
  }
`;

import { FC } from 'react';

//** Components */
import EnterEmailAddressModal from './index';

import { useTestimonials } from 'hooks/growthBook/useTestimonials';
import { LocalStorageProperties } from 'ts/enums/growthbook';
import EnterEmailAddressModalTestimonials31 from './tests/testimonials_3_1';

const pricingPagesDictionary: any = {
  [`${LocalStorageProperties.TESTIMONIALS_A}`]: <EnterEmailAddressModal />,
  [`${LocalStorageProperties.TESTIMONIALS_B}`]: <EnterEmailAddressModalTestimonials31 />,
};

const EmailModalTestABC: FC = () => {
  const testValue = useTestimonials();

  return pricingPagesDictionary[testValue as string] || <EnterEmailAddressModal />;
};

export default EmailModalTestABC;

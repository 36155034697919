import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { sendAnalyticEvent } from 'data/actions/analytics';
import {
  downloadAndConvertToBuffer,
  getUploadLink,
  setConvertDocumentData,
  setPDFfileContent,
  updateDownloadProgress,
  uploadEditDocument,
} from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import { userEmailSelector } from 'data/selectors/user';
import { countPdfPages } from 'helpers/countPdfPages';
import { getFileKeyFromAWSLink } from 'helpers/getFileKeyFromAWSLink';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { EModalTypes } from 'ts/enums/modal.types';
import { IConvertDocumentDto, IEditDocumentDto } from 'ts/interfaces/documents/document';
import { IFormService, IService } from 'ts/interfaces/services/service';
import { formatFileSize } from 'utils/formatFileSize';
import { Analytics } from '../services/analytics';

const useFileUploadAndEdit = ({ service }: { service?: IService | IFormService }) => {
  const navigate = useCustomNavigate();
  const dispatch = useDispatch();
  const email = useSelector(userEmailSelector);

  // after upload a file run the progress animation
  const handleUpdateDownloadProgress = () => {
    let progressValue = 90;
    const intervalId = setInterval(() => {
      progressValue += 1;
      dispatch(updateDownloadProgress(progressValue));
      if (progressValue >= 100) clearInterval(intervalId);
    }, 50);
  };

  const analyticsEventUpload = ({
    success,
    size,
    errorCode,
    fileCounter,
  }: {
    success: boolean;
    size: number;
    errorCode?: number;
    fileCounter?: number;
  }) => {
    dispatch(
      sendAnalyticEvent({
        event: 'file_upload_status',
        data: {
          status: success ? 'success' : 'fail',
          place: 'additional',
          errorCode,
          size: formatFileSize(size),
          accurate_size: size / 1000000,
          fileCounter: fileCounter || 1,
          file_format: '.pdf',
          is_validation_error: success ? 'false' : 'true',
        },
      })
    );
  };

  const handleEditDocument = (dataToEdit: IEditDocumentDto, signUp?: boolean) => {
    handleUpdateDownloadProgress();
    return setTimeout(
      () => {
        dispatch(
          uploadEditDocument(
            dataToEdit,
            (data: any) => {
              setTimeout(
                () => {
                  dispatch(
                    downloadAndConvertToBuffer(
                      data?.id,
                      () => {},
                      (buffer: ArrayBuffer) => {
                        dataToEdit.documentId = data?.id;
                        // set converted data to localStorage and use this data for google auth
                        localStorage.setItem(
                          'dataToEdit',
                          JSON.stringify({
                            file: dataToEdit,
                            service: service?.path,
                            email: email,
                            documentId: data?.id,
                          })
                        );
                        // set converted data to localStorage and use this data for google auth
                        localStorage.setItem(
                          'dataToConvert',
                          JSON.stringify({
                            file: dataToEdit,
                            service: service?.path,
                            email: email,
                            serviceType: service?.serviceType,
                          })
                        );

                        dispatch(setPDFfileContent({ document: buffer }));
                        dispatch(toggleModal({ visible: false }));
                        navigate(`/editor${signUp ? '?upload-file=true' : ''}`);
                        window.scrollTo(0, 0); // Scrolls to the top of the page
                      }
                    )
                  );
                },
                signUp ? 500 : 0
              );
            },
            (error: any) => {
              Analytics.sendEvent({
                event: 'file_upload_status',
                data: {
                  status: 'fail',
                  place: 'additional',
                  errorCode: error?.response?.status,
                  size: formatFileSize(dataToEdit.size),
                  accurate_size: dataToEdit.size / 1000000,
                  file_format: '.pdf',
                  is_validation_error: 'false',
                },
              });
              dispatch(toggleModal({ type: EModalTypes.FILE_UPLOAD_ERROR, visible: true }));
            }
          )
        );
      },
      signUp ? 0 : 1000
    );
  };

  const handleEditFile = (dataToEdit: IEditDocumentDto) => {
    handleEditDocument(dataToEdit);
  };

  const handleUploadFileByLinkToS3 = async (file: File, uploadLink: string) => {
    try {
      dispatch(updateDownloadProgress(0));
      dispatch(
        toggleModal({
          type: EModalTypes.PROGRESS_EDIT_FILE,
          visible: true,
          options: { file },
        })
      );

      const res = await axios.put(uploadLink, file, { headers: {} });
      const from = file?.name?.split('.')?.pop()?.toUpperCase() || service?.from || 'PDF';
      const pagesCount = from === 'PDF' ? await countPdfPages(file) : 1;

      const dataToEdit: IEditDocumentDto = {
        filename: file.name,
        size: file.size,
        key: getFileKeyFromAWSLink(res?.request?.responseURL),
        pagesCount,
        url: res?.request?.responseURL,
        serviceType: service?.serviceType,
      };

      const dataToConvert: IConvertDocumentDto = {
        filename: file.name,
        size: file.size,
        key: getFileKeyFromAWSLink(res?.request?.responseURL),
        url: res?.request?.responseURL,
        pagesCount,
        from,
        to: service?.to || 'PNG',
        serviceType: service?.serviceType,
      };

      // awaiting the downloading animation
      setTimeout(() => {
        dispatch(setConvertDocumentData(dataToConvert));

        handleEditFile(dataToEdit);
        analyticsEventUpload({ size: file?.size, success: true });
      }, 5000);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // upload to s3 bucket and convert file
  const handleUploadFile = (file: File) => {
    if (!file) return;

    dispatch(
      sendAnalyticEvent({
        event: 'features_tap',
        data: {
          features_name: service?.path?.replace('/', '') || '',
          method: 'click',
        },
      })
    );

    dispatch(
      getUploadLink({
        filename: file?.name,
        onSuccess: (res: any) => {
          handleUploadFileByLinkToS3(file, res[0]?.url);
        },
        service,
      })
    );
  };

  return {
    handleUploadFile,
    handleEditDocument,
  };
};

export default useFileUploadAndEdit;

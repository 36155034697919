import Pdf2WordIcon from 'assets/converterIcons/pdf_2_word.svg';
import { Trans } from 'react-i18next';
import { EServiceType, IService } from 'ts/interfaces/services/service';
import { mergeImagesPageService } from './mergeImagesService';
import { mergePageService } from './mergePageService';
import { EServicesKey } from './types';

export const editorServicesList = (t: any): IService[] => {
  return [
    {
      key: EServicesKey.EDIT_PDF,
      serviceType: EServiceType.EDITOR,
      path: '/edit-pdf',
      name: t('landing.edit_pdf.name'),
      nameKey: 'landing.edit_pdf.name',
      title: t('landing.edit_pdf.h1.title'),
      aiTitle: t('landing.edit_pdf.ai_title'),
      description: t('landing.edit_pdf.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.edit_pdf.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.edit_pdf.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.edit_pdf.landing_hero.hero_benefit_3')}</Trans>,
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'PDF',
      buttonLabel: t('landing.edit_pdf.CTA.buttonLabel'),
      availableFormats: '.pdf',
      aboutSection: {
        title: t('landing.edit_pdf.howTo.h2'),
        items: [
          t('landing.edit_pdf.howTo.first'),
          t('landing.edit_pdf.howTo.second'),
          t('landing.edit_pdf.howTo.third'),
        ],
      },
      ctaTitle: t('landing.edit_pdf.CTA.title'),
      meta: {
        title: t('landing.edit_pdf.meta.title'),
        description: t('landing.edit_pdf.meta.description'),
      },
    },
    {
      key: EServicesKey.EDIT_PDF,
      serviceType: EServiceType.EDITOR,
      path: '/edit-pdf-2',
      name: t('landing.edit_pdf.name'),
      nameKey: 'landing.edit_pdf.name',
      title: t('landing.edit_pdf.h1.title'),
      aiTitle: t('landing.edit_pdf.ai_title'),
      description: t('landing.edit_pdf.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.edit_pdf.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.edit_pdf.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.edit_pdf.landing_hero.hero_benefit_3')}</Trans>,
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'PDF',
      buttonLabel: t('landing.edit_pdf.CTA.buttonLabel'),
      availableFormats: '.pdf',
      aboutSection: {
        title: t('landing.edit_pdf.howTo.h2'),
        items: [
          t('landing.edit_pdf.howTo.first'),
          t('landing.edit_pdf.howTo.second'),
          t('landing.edit_pdf.howTo.third'),
        ],
      },
      ctaTitle: t('landing.edit_pdf.CTA.title'),
      meta: {
        title: t('landing.edit_pdf.meta.title'),
        description: t('landing.edit_pdf.meta.description'),
      },
    },
    {
      key: EServicesKey.FILL_PDF,
      serviceType: EServiceType.EDITOR,
      path: '/fill-pdf',
      name: t('landing.fill_pdf.name'),
      nameKey: 'landing.fill_pdf.name',
      title: t('landing.fill_pdf.h1.title'),
      aiTitle: t('landing.fill_pdf.ai_title'),
      description: t('landing.fill_pdf.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.fill_pdf.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.fill_pdf.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.fill_pdf.landing_hero.hero_benefit_3')}</Trans>,
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'DOCX',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('landing.fill_pdf.howTo.h2'),
        items: [
          t('landing.fill_pdf.howTo.first'),
          t('landing.fill_pdf.howTo.second'),
          t('landing.fill_pdf.howTo.third'),
        ],
      },
      ctaTitle: t('landing.fill_pdf.CTA.title'),
      buttonLabel: t('landing.fill_pdf.CTA.buttonLabel'),
      meta: {
        title: t('landing.fill_pdf.meta.title'),
        description: t('landing.fill_pdf.meta.description'),
      },
    },
    {
      key: EServicesKey.DELETE_PAGES_FROM_PDF,
      serviceType: EServiceType.EDITOR,
      path: '/delete-pages-pdf',
      nameKey: 'landing.delete_pages_pdf.name',
      name: t('landing.delete_pages_pdf.name'),
      title: t('landing.delete_pages_pdf.h1.title'),
      description: t('landing.delete_pages_pdf.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.delete_pages_pdf.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.delete_pages_pdf.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.delete_pages_pdf.landing_hero.hero_benefit_3')}</Trans>,
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'DOCX',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('landing.delete_pages_pdf.howTo.h2'),
        items: [
          t('landing.delete_pages_pdf.howTo.first'),
          t('landing.delete_pages_pdf.howTo.second'),
          t('landing.delete_pages_pdf.howTo.third'),
        ],
      },
      ctaTitle: t('landing.delete_pages_pdf.CTA.title'),
      buttonLabel: t('landing.delete_pages_pdf.CTA.buttonLabel'),
      meta: {
        title: t('landing.delete_pages_pdf.meta.title'),
        description: t('landing.delete_pages_pdf.meta.description'),
      },
    },
    {
      key: EServicesKey.PDF_CONVERTOR,
      serviceType: EServiceType.PDF_CONVERTOR,
      path: '/pdf-converter',
      name: t('landing.pdf_converter.name'),
      nameKey: 'landing.pdf_converter.name',
      title: t('landing.pdf_converter.h1.title'),
      aiTitle: t('landing.pdf_converter.ai_title'),
      description: t('landing.pdf_converter.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.pdf_converter.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.pdf_converter.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.pdf_converter.landing_hero.hero_benefit_3')}</Trans>,
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'DOCX',
      // TODO: Removed unsupported images
      // availableFormats:
      //   '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .jpg, .jpeg, .png, .gif, .svg, .webp, .bmp, .eps',
      availableFormats:
        '.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .jpg, .jpeg, .png, .gif, .webp, .bmp, .odt, .heic, .txt, .rtf, .html, .ods, .pages, .epub, .jfif',
      aboutSection: {
        title: t('landing.pdf_converter.howTo.h2'),
        items: [
          t('landing.pdf_converter.howTo.first'),
          t('landing.pdf_converter.howTo.second'),
          t('landing.pdf_converter.howTo.third'),
        ],
      },
      ctaTitle: t('landing.pdf_converter.CTA.title'),
      buttonLabel: t('landing.pdf_converter.CTA.buttonLabel'),
      meta: {
        title: t('landing.pdf_converter.meta.title'),
        description: t('landing.pdf_converter.meta.description'),
      },
    },
    {
      key: EServicesKey.COMPRESS_PDF,
      serviceType: EServiceType.COMPRESSOR,
      path: '/compress-pdf',
      name: t('landing.compress_pdf.name'),
      nameKey: 'landing.compress_pdf.name',
      title: t('landing.compress_pdf.h1.title'),
      aiTitle: t('landing.compress_pdf.ai_title'),
      description: t('landing.compress_pdf.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.compress_pdf.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.compress_pdf.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.compress_pdf.landing_hero.hero_benefit_3')}</Trans>,
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('landing.compress_pdf.howTo.h2'),
        items: [
          t('landing.compress_pdf.howTo.first'),
          t('landing.compress_pdf.howTo.second'),
          t('landing.compress_pdf.howTo.third'),
        ],
      },
      ctaTitle: t('landing.compress_pdf.CTA.title'),
      buttonLabel: t('landing.compress_pdf.CTA.buttonLabel'),
      meta: {
        title: t('landing.compress_pdf.meta.title'),
        description: t('landing.compress_pdf.meta.description'),
      },
    },
    {
      key: EServicesKey.COMPRESS_IMAGES,
      serviceType: EServiceType.COMPRESSOR,
      path: '/compress-images',
      name: t('landing.compress_images.name'),
      nameKey: 'landing.compress_images.name',
      title: t('landing.compress_images.h1.title'),
      aiTitle: t('landing.compress_images.ai_title'),
      description: t('landing.compress_images.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.compress_images.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.compress_images.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.compress_images.landing_hero.hero_benefit_3')}</Trans>,
      ],
      icon: Pdf2WordIcon,
      from: 'Image',
      to: 'Image',
      availableFormats: '.png, .jpeg, .jpg, .heic',
      aboutSection: {
        title: t('landing.compress_images.howTo.h2'),
        items: [
          t('landing.compress_images.howTo.first'),
          t('landing.compress_images.howTo.second'),
          t('landing.compress_images.howTo.third'),
        ],
      },
      ctaTitle: t('landing.compress_images.CTA.title'),
      buttonLabel: t('landing.compress_images.CTA.buttonLabel'),
      meta: {
        title: t('landing.compress_images.meta.title'),
        description: t('landing.compress_images.meta.description'),
      },
    },
    {
      key: EServicesKey.SIGN_PDF,
      serviceType: EServiceType.EDITOR,
      path: '/sign-pdf',
      name: t('landing.sign_pdf.name'),
      nameKey: 'landing.sign_pdf.name',
      title: t('landing.sign_pdf.h1.title'),
      description: t('landing.sign_pdf.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.sign_pdf.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.sign_pdf.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.sign_pdf.landing_hero.hero_benefit_3')}</Trans>,
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'DOCX',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('landing.sign_pdf.howTo.h2'),
        items: [
          t('landing.sign_pdf.howTo.first'),
          t('landing.sign_pdf.howTo.second'),
          t('landing.sign_pdf.howTo.third'),
        ],
      },
      ctaTitle: t('landing.sign_pdf.CTA.title'),
      buttonLabel: t('landing.sign_pdf.CTA.buttonLabel'),
      meta: {
        title: t('landing.sign_pdf.meta.title'),
        description: t('landing.sign_pdf.meta.description'),
      },
    },
    mergePageService(t),
    mergeImagesPageService(t),
    {
      key: EServicesKey.SPLIT_PDF,
      serviceType: EServiceType.SPLITTER,
      path: '/split-pdf',
      name: t('landing.split_pdf.name'),
      nameKey: 'landing.split_pdf.name',
      title: t('landing.split_pdf.h1.title'),
      description: t('landing.split_pdf.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.split_pdf.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.split_pdf.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.split_pdf.landing_hero.hero_benefit_3')}</Trans>,
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
      aboutSection: {
        title: t('landing.split_pdf.howTo.h2'),
        items: [
          t('landing.split_pdf.howTo.first'),
          t('landing.split_pdf.howTo.second'),
          t('landing.split_pdf.howTo.third'),
        ],
      },
      ctaTitle: t('landing.split_pdf.CTA.title'),
      buttonLabel: t('landing.split_pdf.CTA.buttonLabel'),
      meta: {
        title: t('landing.split_pdf.meta.title'),
        description: t('landing.split_pdf.meta.description'),
      },
    },
    {
      key: EServicesKey.IMAGE_TO_TEXT,
      serviceType: EServiceType.OCR,
      path: '/image-to-text',
      name: t('landing.image_to_text.name'),
      nameKey: 'landing.image_to_text.name',
      title: t('landing.image_to_text.h1.title'),
      aiTitle: t('landing.image_to_text.ai_title'),
      description: t('landing.image_to_text.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.image_to_text.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.image_to_text.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.image_to_text.landing_hero.hero_benefit_3')}</Trans>,
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf, .png, .jpg, .bmp, .jpeg, .tiff, .gif, .djvu',
      aboutSection: {
        title: t('landing.image_to_text.howTo.h2'),
        items: [
          t('landing.image_to_text.howTo.first'),
          t('landing.image_to_text.howTo.second'),
          t('landing.image_to_text.howTo.third'),
        ],
      },
      ctaTitle: t('landing.image_to_text.CTA.title'),
      buttonLabel: t('landing.image_to_text.CTA.buttonLabel'),
      meta: {
        title: t('landing.image_to_text.meta.title'),
        description: t('landing.image_to_text.meta.description'),
      },
    },
    {
      key: EServicesKey.PDF_OCR,
      serviceType: EServiceType.OCR,
      path: '/ocr-pdf',
      name: t('landing.pdf_ocr.name'),
      nameKey: 'landing.pdf_ocr.name',
      title: t('landing.pdf_ocr.h1.title'),
      aiTitle: t('landing.pdf_ocr.ai_title'),
      description: t('landing.pdf_ocr.h1.subtitle'),
      heroFeatures: [
        <Trans>{t('landing.pdf_ocr.landing_hero.hero_benefit_1')}</Trans>,
        <Trans>{t('landing.pdf_ocr.landing_hero.hero_benefit_2')}</Trans>,
        <Trans>{t('landing.pdf_ocr.landing_hero.hero_benefit_3')}</Trans>,
      ],
      icon: Pdf2WordIcon,
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf, .png, .jpg, .bmp, .jpeg, .tiff, .gif, .djvu',
      aboutSection: {
        title: t('landing.pdf_ocr.howTo.h2'),
        items: [
          t('landing.pdf_ocr.howTo.first'),
          t('landing.pdf_ocr.howTo.second'),
          t('landing.pdf_ocr.howTo.third'),
        ],
      },
      ctaTitle: t('landing.pdf_ocr.CTA.title'),
      buttonLabel: t('landing.pdf_ocr.CTA.buttonLabel'),
      meta: {
        title: t('landing.pdf_ocr.meta.title'),
        description: t('landing.pdf_ocr.meta.description'),
      },
    },
  ];
};

import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import closeImagePath from 'assets/close-icon.svg';
import GoogleIcon from 'assets/google.svg';
import LogoImage from 'assets/logo.svg';
import CommonButton from 'components/common/button';
import EmailAutoCompleteInput from 'components/common/inputEmailAutoComplete';
import CommonLink from 'components/common/link';
import openNotification from 'components/commonNotification';
import { toggleModal } from 'data/actions/modals';
import { createAnonymousUser, createUserReveal, getUser } from 'data/actions/user';
import { modalOptionsSelector } from 'data/selectors/modals';
import { isEmailValid } from 'helpers/validation';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import { GoogleButton } from 'layouts/authForm/styles';
import { EModalTypes } from 'ts/enums/modal.types';
import { ENotification } from 'ts/interfaces/common/notification';

import { CloseIcon, ModalContainer } from 'components/modals/baseModal/styles';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { convertDocumentDataSelector } from 'data/selectors/documents';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';
import useDisableScroll from 'hooks/useDisableScroll';
import { useGoogleAuthButton } from 'hooks/useGoogleAuthButton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { beforeUnloadCallback } from 'utils/beforeUnload';
import { getPrevPage } from 'utils/prevPath';
import {
  Container,
  ContentWrapper,
  Description,
  InputContainer,
  InputsWrapper,
  LabeledDivider,
  LoginLinkContainer,
  Logo,
  LogoContainer,
  SignUpLink,
  TesimonialBlockContainer,
  TestimonialsModalContainer,
  Title,
} from './styles';
import { TestimonialBlock } from './components/testimonialBlock';

const EnterEmailAddressModalTestimonials31: FC = () => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  const { connectGmailAccount } = useGoogleAuthButton();
  useDisableScroll();
  useCloseModalOnBack();

  const { handleConvertDocument, servicePath, keepOpenedOnSuccess } =
    useSelector(modalOptionsSelector);

  const dataToConvert = useSelector(convertDocumentDataSelector());

  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState({ email: '' });

  useEffect(() => {
    dispatch(
      sendAnalyticEvent({
        event: 'email_enter_modal_view',
        data: {
          screen_config_name: 'google_without_password',
          fromPage: getPrevPage() || '',
        },
      })
    );
  }, [dispatch]);

  const validateEmail = (email: string) => {
    if (!isEmailValid(email)) {
      setErrors((prev) => ({
        ...prev,
        email: t('global.please_enter_valid_email_error'),
      }));
      return false;
    }
    setErrors((prev) => ({ ...prev, email: '' }));
    return true;
  };

  const onFailedSignUp = () => {
    dispatch(
      sendAnalyticEvent({
        event: 'email_already_exist_popup',
      })
    );
    openNotification({
      message: (
        <div>
          {t('auth_form.email_already_exist_error')}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(toggleModal({ visible: true, type: EModalTypes.AUTH_FORM }))}
          >
            {' '}
            {t('auth_form.log_in_button')}
          </span>
        </div>
      ),
      type: ENotification.ERROR,
      duration: 10,
    });
  };

  const onSuccessSignUp = () => {
    const handleNavigate = (route: string) => {
      dispatch(toggleModal({ visible: false }));
      navigate(route);
      window.scrollTo(0, 0); // Scrolls to the top of the page
    };
    window.onbeforeunload = null;

    dispatch(
      sendAnalyticEvent({
        event: 'email_enter_tap',
        data: { email, type: 'email' },
      })
    );

    // TODO: Check is this is relevant after analytics check
    dispatch(
      getUser({
        onSuccess: () => {
          if (typeof handleConvertDocument === 'function') {
            handleConvertDocument();
            if (!keepOpenedOnSuccess) {
              dispatch(toggleModal({ visible: false }));
            }
            return;
          }
          handleNavigate('/app/dashboard');
          if (!keepOpenedOnSuccess) {
            dispatch(toggleModal({ visible: false }));
          }
        },
        onFailed: () =>
          dispatch(
            createAnonymousUser(() => {
              if (typeof handleConvertDocument === 'function') {
                handleConvertDocument();
                if (!keepOpenedOnSuccess) {
                  dispatch(toggleModal({ visible: false }));
                }
                return;
              }
              handleNavigate('/app/dashboard');
              if (!keepOpenedOnSuccess) {
                dispatch(toggleModal({ visible: false }));
              }
            })
          ),
      })
    );
  };

  const handleDownLoadFile = () => {
    const isValidEmail = validateEmail(email);
    if (!isValidEmail) return;

    dispatch(
      createUserReveal({
        email,
        last_uploaded_filename: dataToConvert?.filename,
        onSuccess: onSuccessSignUp,
        onFailed: onFailedSignUp,
      })
    );
  };

  const handleLogin = () => {
    dispatch(
      toggleModal({
        type: EModalTypes.AUTH_FORM,
        visible: true,
        options: {
          handleConvertDocument,
          servicePath,
          reveal: true,
        },
      })
    );
  };

  const handleModalClose = () => {
    dispatch(
      sendAnalyticEvent({
        event: 'enter_email_close_tap',
      })
    );
    window.onbeforeunload = beforeUnloadCallback;
    dispatch(toggleModal({ visible: false }));
  };

  const descriptionElement = useMemo(
    () => (
      <Description>
        {t('auth_form.consent_sentence.0')}{' '}
        <CommonLink size="13.4px" to="/terms" target="_blank">
          {t('auth_form.consent_sentence.1')}
        </CommonLink>
        , {t('auth_form.consent_sentence.2')}{' '}
        <CommonLink size="13.4px" to="/privacy-policy" target="_blank">
          {t('auth_form.consent_sentence.3')}
        </CommonLink>
      </Description>
    ),
    [t]
  );

  return (
    <ModalContainer>
      <TestimonialsModalContainer>
        <CloseIcon onClick={() => handleModalClose()} src={closeImagePath} alt="" />
        <Container>
          <LogoContainer>
            <Logo src={LogoImage} alt="TheBestPDF logo" />
          </LogoContainer>

          <ContentWrapper>
            <Title>{t('global.to_download_file')}</Title>

            <GoogleButton onClick={connectGmailAccount}>
              <LazyLoadImage src={GoogleIcon} alt="google-icon" />
              {t('auth_form.continue_with_google_button')}
            </GoogleButton>
            <LabeledDivider>
              <span>{t('global.login_method_separator')}</span>
            </LabeledDivider>

            <InputsWrapper>
              <InputContainer>
                <EmailAutoCompleteInput
                  onChange={(value: string) => setEmail(value)}
                  value={email}
                  placeholder={t('auth_form.email_address_input_placeholder')}
                  label={t('auth_form.email_address_input_label')}
                  error={errors.email}
                />
              </InputContainer>
            </InputsWrapper>

            <CommonButton type="primary" onClick={() => handleDownLoadFile()}>
              {t('global.download_file')}
            </CommonButton>

            {descriptionElement}

            <LoginLinkContainer>
              <div>
                <span>{t('auth_form.have_an_account')}</span>
                <SignUpLink onClick={handleLogin}>{t('auth_form.log_in_button')}</SignUpLink>
              </div>
            </LoginLinkContainer>
          </ContentWrapper>
        </Container>
        <TesimonialBlockContainer>
          <TestimonialBlock />
        </TesimonialBlockContainer>
      </TestimonialsModalContainer>
    </ModalContainer>
  );
};

export default EnterEmailAddressModalTestimonials31;

import { useFeature } from '@growthbook/growthbook-react';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { GrowthBookTestType, LocalStorageProperties } from 'ts/enums/growthbook';

export const useMobileSelectPlan = (): LocalStorageProperties => {
  const abTests = getJsonFomStorage('abTests');
  const mobileSelectPlan = useFeature(GrowthBookTestType.MOBILE_SELECT_PLAN);

  if (abTests?.includes(LocalStorageProperties.MOBILE_SELECT_PLAN_B))
    return LocalStorageProperties.MOBILE_SELECT_PLAN_B;

  if (mobileSelectPlan?.experiment?.key && mobileSelectPlan?.experimentResult?.value) {
    return `${mobileSelectPlan?.experiment?.key}_${mobileSelectPlan?.experimentResult?.value}` as LocalStorageProperties;
  }

  return LocalStorageProperties.MOBILE_SELECT_PLAN_A;
};

import checkMarkIcon from 'assets/checkmark.svg';
import LockedIcon from 'assets/locked-alt.svg';
import usePaypalScript from 'components/checkout/hooks/usePaypalScript';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { getDocumentById, getDocuments } from 'data/actions/documents';
import { initAlternativeSubscription, initPaymentCard } from 'data/actions/subscriptions';
import { selectedPlanSelector } from 'data/selectors/subscriptions';
import {
  isLatAmUserSelector,
  isReversedCurrencyPositionSelector,
  userCurrencySelector,
  userEmailSelector,
} from 'data/selectors/user';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { useBasePath } from 'hooks/useCustomNavigate';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentMethod } from 'ts/interfaces/order/order';
import TrustPilotCarousel from './parts/trustpilot';
import PaymentForm from './paymentForm';
import {
  AdditionalPaymentInfo,
  Container,
  Description,
  FeatureIcon,
  FeatureItem,
  FeaturesContainer,
  NortonContainer,
  Price,
  PriceContainer,
  SelectedPlanData,
  SelectedPlanName,
} from './styles';

const PaymentFormTestB = () => {
  const [merchantData, setMerchantData] = useState<any>(null);
  const [paypalScript, setPaypalScript] = useState<string | null>(null);
  const selectedPlan = useSelector(selectedPlanSelector) || getJsonFomStorage('selectedPlan');
  const dispatch = useDispatch();
  const email = useSelector(userEmailSelector);
  const { t } = useTranslation();
  const userCurrency = useSelector(userCurrencySelector);
  const basePath = useBasePath();
  const [queryDocumentID, setQueryDocumentID] = useState<string | null>(null);
  const isReversedCurrencyPosition = useSelector(isReversedCurrencyPositionSelector);

  const isLatAmUser = useSelector(isLatAmUserSelector);

  useEffect(() => {
    if (window.location.search) {
      const parsed = queryString.parse(window.location.search);
      if (parsed?.['documentId']) setQueryDocumentID(parsed?.['documentId'] as string);
    }
  }, []);

  useEffect(() => {
    window.analytics.track('payment_screen_view');
    dispatch(
      sendAnalyticEvent({
        event: 'payment_screen_view',
        data: { screen_config_name: 'payment_modal_social_proof' },
      })
    );
  }, [dispatch]);

  usePaypalScript({
    paypalScript,
    signature: merchantData?.signature,
  });

  useEffect(() => {
    if (queryDocumentID) {
      dispatch(getDocumentById(queryDocumentID));
      return;
    }

    dispatch(
      getDocuments((res: any) => {
        dispatch(getDocumentById(res?.files[0]?.id));
      })
    );
  }, [dispatch, queryDocumentID]);

  useEffect(() => {
    dispatch(
      sendAnalyticEvent({
        event: 'payment_card_init_start',
        data: {
          selectedPlanId: selectedPlan?.id ?? '',
        },
      })
    );

    dispatch(
      initPaymentCard(selectedPlan?.id, (res: any) => {
        dispatch(
          sendAnalyticEvent({
            event: 'payment_card_init_end',
            data: {
              merchant: res?.merchant || '',
              signature: res?.signature || '',
              paymentIntent: res?.paymentIntent || '',
              productsId: selectedPlan?.id || '',
            },
          })
        );

        setMerchantData({
          merchant: res?.merchant,
          signature: res?.signature,
          paymentIntent: res?.paymentIntent,
          productsId: selectedPlan?.id,
        });
      })
    );

    if (isLatAmUser) {
      return;
    }

    dispatch(
      sendAnalyticEvent({
        event: 'payment_paypal_init_start',
        data: {
          productId: selectedPlan?.id as string,
          customerEmail: (email as string) || (localStorage.getItem('email') as string),
          paymentMethod: PaymentMethod.Paypal,
        },
      })
    );

    // get paypal payment url
    dispatch(
      initAlternativeSubscription({
        productId: selectedPlan?.id as string,
        customerEmail: (email as string) || (localStorage.getItem('email') as string),
        paymentMethod: PaymentMethod.Paypal,
        onSuccess: (res: any) => {
          dispatch(
            sendAnalyticEvent({
              event: 'payment_paypal_init_end',
              data: {
                paypalUrl: res.url || '',
                userEmail: email || '',
                orderId: res.orderId || '',
              },
            })
          );

          if (!localStorage.getItem('email') && email) localStorage.setItem('email', email);
          setPaypalScript(res.url);
          localStorage.setItem('orderId', res.orderId);
        },
      })
    );
  }, [dispatch]); // eslint-disable-line

  const handleTryAgain = () => {
    setMerchantData(null);
    setPaypalScript(null);

    dispatch(
      initPaymentCard(selectedPlan?.id, (res: any) => {
        setMerchantData({
          merchant: res?.merchant,
          signature: res?.signature,
          paymentIntent: res?.paymentIntent,
          productsId: selectedPlan?.id,
        });
      })
    );

    if (isLatAmUser) {
      return;
    }

    // get paypal payment url
    dispatch(
      initAlternativeSubscription({
        productId: selectedPlan?.id as string,
        customerEmail: (email as string) || (localStorage.getItem('email') as string),
        paymentMethod: PaymentMethod.Paypal,
        onSuccess: (res: any) => {
          if (!localStorage.getItem('email') && email) localStorage.setItem('email', email);
          setPaypalScript(res.url);
          localStorage.setItem('orderId', res.orderId);
        },
      })
    );
  };

  const handleSubTermClick = () => {
    dispatch(sendAnalyticEvent({ event: 'subscription_terms_tap' }));
  };

  return (
    <>
      <Container>
        <div style={{ width: '50%', position: 'relative', paddingRight: '15px' }}>
          {merchantData?.signature && (
            <PaymentForm
              merchantData={merchantData}
              handleTryAgain={handleTryAgain}
              paypalScript={isLatAmUser ? null : paypalScript}
            />
          )}
        </div>
        <SelectedPlanData>
          <SelectedPlanName>
            <span>{t(selectedPlan?.testBillingName || '')} </span>
            <span>{t(selectedPlan?.testName || '')}</span>
          </SelectedPlanName>
          <PriceContainer>
            <Price>
              {isReversedCurrencyPosition ? (
                <>
                  {selectedPlan?.isYear ? selectedPlan?.fullPrice : selectedPlan?.price}
                  {selectedPlan?.currencySymbol || userCurrency}
                </>
              ) : (
                <>
                  {selectedPlan?.currencySymbol || userCurrency}
                  {selectedPlan?.isYear ? selectedPlan?.fullPrice : selectedPlan?.price}
                </>
              )}
            </Price>
          </PriceContainer>
          <FeaturesContainer>
            {selectedPlan?.features.map((feature: any, index: number) => (
              <FeatureItem key={`plan-feature-${index + 1}`}>
                <FeatureIcon src={checkMarkIcon} />
                {t(feature)}
              </FeatureItem>
            ))}
          </FeaturesContainer>
          <TrustPilotCarousel />
          <AdditionalPaymentInfo>
            <NortonContainer>
              <LazyLoadImage src={LockedIcon} alt="locked-icon" />
              <span>{t('checkout_page.your_payment_secured')}</span>
            </NortonContainer>

            <Description>
              {t(
                selectedPlan?.descriptionPayment,
                isReversedCurrencyPosition
                  ? {
                      currencySymbol: selectedPlan?.fullPrice,
                      fullPrice: selectedPlan?.currencySymbol,
                    }
                  : {
                      currencySymbol: selectedPlan?.currencySymbol,
                      fullPrice: selectedPlan?.fullPrice,
                    }
              )}
              {` `}
              <a
                target="_blank"
                onClick={handleSubTermClick}
                href={`${process.env.REACT_APP_DASHBOARD_URL}${basePath}/subscription-terms`}
                rel="noreferrer"
              >
                {t('global.subscription_terms_page_link')}
              </a>
              .
            </Description>
          </AdditionalPaymentInfo>
        </SelectedPlanData>
      </Container>
    </>
  );
};

export default PaymentFormTestB;

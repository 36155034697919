export enum EServicesKey {
  EDIT_PDF = 'edit-pdf',
  FILL_PDF = 'fill-pdf',
  DELETE_PAGES_FROM_PDF = 'delete-pages-from-pdf',
  COMPRESS_PDF = 'compress-pdf',
  COMPRESS_IMAGES = 'compress-images',
  SIGN_PDF = 'sign-pdf',
  SPLIT_PDF = 'split-pdf',
  CREATE_PDF = 'create-pdf',
  ROTATE_PDF = 'rotate-pdf',
  PDF_CONVERTOR = 'pdf-converter',
  IMAGE_TO_TEXT = 'image-to-text',
  PDF_OCR = 'pdf-ocr',
  MERGE_PDF = 'merge-pdf',
  MERGE_IMAGES = 'merge-images',
  PDF_TO_WORD = 'pdf-to-word',
  PDF_TO_PNG = 'pdf-to-png',
  PDF_TO_JPEG = 'pdf-to-jpeg',
  PDF_TO_PDF = 'pdf-to-pdf',
  PDF_TO_PPTX = 'pdf-to-pptx',
  PDF_TO_RTF = 'pdf-to-rtf',
  PDF_TO_XLSX = 'pdf-to-xlsx',
  WORD_TO_PDF = 'word-to-pdf',
  WEBP_TO_JPG = 'webp-to-jpg',
  JPG_TO_WEBP = 'jpg-to-webp',
  PPTX_TO_PDF = 'pptx-to-pdf',
  RTF_TO_PDF = 'rtf-to-pdf',
  XLSX_TO_PDF = 'xlsx-to-pdf',
  IMAGE_TO_PDF = 'image-to-pdf',
  EPUB_TO_PDF = 'epub-to-pdf',
  JPG_TO_PDF = 'jpg-to-pdf',
  PNG_TO_PDF = 'png-to-pdf',
  PDF_TO_EPUB = 'pdf-to-epub',
  PDF_TO_EXCEL = 'pdf-to-excel',
  PDF_TO_JPG = 'pdf-to-jpg',
  MAIN_PAGE = 'main-page',
}

export enum GrowthBookTestType {
  LONG_TRIALS = 'ab-14_days_trial',
  UPDATE_SALES_SCREEN = 'ab-update_sales_screen_1_7_8',
  NEW_PAYMENT_SCREEN = 'ab-original_payment_screen_1_10_0',
  FREE_ACCESS_TEST = 'ab-add_free_access_1_9_6',
  NEW_PRICING_SCREEN = 'abc-sales_2_plans_vs_original_1_10_0',
  NEW_RECURRENT_PRICE = 'ab-new_recurrent_price_40_2_0_1',
  DISCOUNT_SALES = 'abc-promocodes_2_7',
  SEND_FILE_MODAL = 'abc-send_file_modal_2_0_1',
  MODAL_SALES_PAYMENT = 'abc-modal_sales_payment_2_2_4',
  SALES_SCREEN_WITH_AI = 'ab-sales_screen_with_ai_2_3',
  COMPRESS = 'ab-compress_2_8',
  FILE_FOR_FREE = 'ab-your_file_for_free_2_9',
  DOCX_FILE_PREVIEW = 'ab-docx-file-preview_3_0',
  CHECKBOX_TEST = 'abcd-v1_checkbox_2_9',
  DESCRIPTOR_SALES = 'abc-legal_descriptor_2_9',
  TESTIMONIALS = 'ab-testimonials_3_1',
  MOBILE_SELECT_PLAN = 'ab-mobile_select_plan_3_1',
  TEST_MOBILE_SETTING = 'ab-test-mobile-setting-dev',
}

export enum UserProperties {
  UPDATE_SALES_SCREEN = 'ab-update_sales_screen_1_7_8_b',
  NEW_CHECKOUT_SCREEN_B = 'abc-refresh_payment_screen_1_9_4_B',
}

export enum LocalStorageProperties {
  LONG_TRIALS_A = 'ab-14_days_trial_A',
  LONG_TRIALS_B = 'ab-14_days_trial_B',
  DISCOUNT_SALES = 'abc-promocodes_2_7',
  DISCOUNT_SALES_A = 'abc-promocodes_2_7_A',
  DISCOUNT_SALES_B = 'abc-promocodes_2_7_B',
  DISCOUNT_SALES_C = 'abc-promocodes_2_7_C',
  MOBILE_SELECT_PLAN_A = 'ab-mobile_select_plan_3_1_A',
  MOBILE_SELECT_PLAN_B = 'ab-mobile_select_plan_3_1_B',
  DESCRIPTOR_SALES_A = 'abc-legal_descriptor_2_9_A',
  DESCRIPTOR_SALES_B = 'abc-legal_descriptor_2_9_B',
  DESCRIPTOR_SALES_C = 'abc-legal_descriptor_2_9_C',
  UPDATE_SALES_SCREEN = 'ab-update_sales_screen_1_7_8',
  UPDATE_SALES_SCREEN_B = 'ab-update_sales_screen_1_7_8_B',
  UPDATE_SALES_SCREEN_A = 'ab-update_sales_screen_1_7_8_A',
  UPDATE_SALES_SCREEN_C = 'ab-update_sales_screen_1_7_8_C',
  NEW_CHECKOUT_SCREEN_A = 'ab-original_payment_screen_1_10_0_A',
  NEW_CHECKOUT_SCREEN_B = 'ab-original_payment_screen_1_10_0_B',
  NEW_PRICING_SCREEN_A = 'abc-sales_2_plans_vs_original_1_10_0_A',
  NEW_PRICING_SCREEN_B = 'abc-sales_2_plans_vs_original_1_10_0_B',
  NEW_PRICING_SCREEN_C = 'abc-sales_2_plans_vs_original_1_10_0_C',
  FREE_ACCESS_TEST_A = 'ab-add_free_access_1_9_6_A',
  FREE_ACCESS_TEST_B = 'ab-add_free_access_1_9_6_B',
  NEW_RECURRENT_PRICE_TEST_A = 'ab-new_recurrent_price_40_2_0_1_A',
  NEW_RECURRENT_PRICE_TEST_B = 'ab-new_recurrent_price_40_2_0_1_B',
  SEND_FILE_MODAL_TEST_A = 'abc-send_file_modal_2_0_1_A',
  SEND_FILE_MODAL_TEST_B = 'abc-send_file_modal_2_0_1_B',
  SEND_FILE_MODAL_TEST_C = 'abc-send_file_modal_2_0_1_C',
  MODAL_SALES_PAYMENT_A = 'abc-modal_sales_payment_2_2_4_A',
  MODAL_SALES_PAYMENT_B = 'abc-modal_sales_payment_2_2_4_B',
  MODAL_SALES_PAYMENT_C = 'abc-modal_sales_payment_2_2_4_C',
  SALES_SCREEN_WITH_AI_A = 'ab-sales_screen_with_ai_2_3_A',
  SALES_SCREEN_WITH_AI_B = 'ab-sales_screen_with_ai_2_3_B',
  COMPRESS_A = 'ab-compress_2_8_A',
  COMPRESS_B = 'ab-compress_2_8_B',
  FILE_FOR_FREE_A = 'ab-your_file_for_free_2_9_A',
  FILE_FOR_FREE_B = 'ab-your_file_for_free_2_9_B',
  DOCX_FILE_PREVIEW_A = 'ab-docx-file-preview_3_0_A',
  DOCX_FILE_PREVIEW_B = 'ab-docx-file-preview_3_0_B',
  CHECKBOX_TEST_A = 'abcd-v1_checkbox_2_9_A',
  CHECKBOX_TEST_B = 'abcd-v1_checkbox_2_9_B',
  CHECKBOX_TEST_C = 'abcd-v1_checkbox_2_9_C',
  CHECKBOX_TEST_D = 'abcd-v1_checkbox_2_9_D',
  TESTIMONIALS_A = 'ab-testimonials_3_1_A',
  TESTIMONIALS_B = 'ab-testimonials_3_1_B',
  TEST_MOBILE_SETTING_A = 'ab-test-mobile-setting-dev_A',
  TEST_MOBILE_SETTING_B = 'ab-test-mobile-setting-dev_B',
}

import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleModal } from 'data/actions/modals';
import owlImagePath from 'assets/owl.svg';
import closeImagePath from 'assets/close-icon.svg';
import { userEmailSelector } from 'data/selectors/user';
import { FinalStepContainer, TitleFinalStep, Description, OwlImage } from '../styles';
import { CloseIcon } from '../../baseModal/styles';
import { useTranslation } from 'react-i18next';
import { sendAnalyticEvent } from 'data/actions/analytics';

const FinalStep: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const email = useSelector(userEmailSelector);

  useEffect(() => {
    dispatch(
      sendAnalyticEvent({
        event: 'cancel_plan_finish_screen',
      })
    );
  }, [dispatch]);

  const handleClose = () => {
    dispatch(
      sendAnalyticEvent({
        event: 'cancel_plan_finish_tap',
      })
    );
    dispatch(toggleModal({ visible: false }));
  };

  return (
    <FinalStepContainer>
      <CloseIcon onClick={handleClose} src={closeImagePath} alt="" />
      <OwlImage alt="" src={owlImagePath} />
      <TitleFinalStep>{t('cancel_plan_modal.title')}</TitleFinalStep>
      <Description style={{ marginBottom: 10 }}>{t('cancel_plan_modal.description')}</Description>
      <Description>
        {t('cancel_plan_modal.thank_you', {
          email: email,
        })}
      </Description>
    </FinalStepContainer>
  );
};

export default FinalStep;

import { useFeature } from '@growthbook/growthbook-react';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { GrowthBookTestType, LocalStorageProperties } from 'ts/enums/growthbook';

export const useCheckboxV1 = (): LocalStorageProperties => {
  const abTests = getJsonFomStorage('abTests');
  const checkboxTest = useFeature(GrowthBookTestType.CHECKBOX_TEST);

  if (abTests?.includes(LocalStorageProperties.CHECKBOX_TEST_B))
    return LocalStorageProperties.CHECKBOX_TEST_B;
  else if (abTests?.includes(LocalStorageProperties.CHECKBOX_TEST_C))
    return LocalStorageProperties.CHECKBOX_TEST_C;
  else if (abTests?.includes(LocalStorageProperties.CHECKBOX_TEST_D))
    return LocalStorageProperties.CHECKBOX_TEST_D;

  if (checkboxTest?.experiment?.key && checkboxTest?.experimentResult?.value) {
    return `${checkboxTest?.experiment?.key}_${checkboxTest?.experimentResult?.value}` as LocalStorageProperties;
  }

  return LocalStorageProperties.CHECKBOX_TEST_A;
};

import Pdf2WordIcon from 'assets/converterIcons/pdf_2_word.svg';
import { Trans } from 'react-i18next';
import { EServiceType, IService } from 'ts/interfaces/services/service';
import { EServicesKey } from './types';

export const mergePageService = (t: any): IService => {
  return {
    key: EServicesKey.MERGE_PDF,
    serviceType: EServiceType.MERGER,
    path: '/merge-pdf',
    name: t('landing.merge_pdf.name'),
    nameKey: 'landing.merge_pdf.name',
    title: t('landing.merge_pdf.h1.title'),
    description: t('landing.merge_pdf.h1.subtitle'),
    heroFeatures: [
      <Trans>{t('landing.merge_pdf.landing_hero.hero_benefit_1')}</Trans>,
      <Trans>{t('landing.merge_pdf.landing_hero.hero_benefit_2')}</Trans>,
      <Trans>{t('landing.merge_pdf.landing_hero.hero_benefit_3')}</Trans>,
    ],
    icon: Pdf2WordIcon,
    from: 'PDF',
    to: 'DOCX',
    availableFormats: '.pdf',
    aboutSection: {
      title: t('landing.merge_pdf.howTo.h2'),
      items: [
        t('landing.merge_pdf.howTo.first'),
        t('landing.merge_pdf.howTo.second'),
        t('landing.merge_pdf.howTo.third'),
      ],
    },
    ctaTitle: t('landing.merge_pdf.CTA.title'),
    buttonLabel: t('landing.merge_pdf.CTA.buttonLabel'),
    meta: {
      title: t('landing.merge_pdf.meta.title'),
      description: t('landing.merge_pdf.meta.description'),
    },
  };
};

import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { IDocumentsState } from 'ts/interfaces/documents/document';
import { getJsonFomStorage } from 'helpers/localStorageHelper';

const initialState: IDocumentsState = {
  documents: {
    data: [],
    meta: {},
  },
  uploadLinks: null,
  downloadProgress: 0,
  convertDocumentData: null,
  editDocumentData: null,
  pdfFileContent: null,
  fileUpload: null,
  documentById: null,
  editFilename: '',
  mergeDocumentsList: [],
  sendNotification: false,
};

const documentsReducer = (state = initialState, action: IReduxAction) => {
  switch (action.type) {
    case EReduxTypes.UPLOAD_DOCUMENT: {
      return {
        ...state,
      };
    }

    case EReduxTypes.SIGN_OUT: {
      return initialState;
    }

    case EReduxTypes.GET_UPLOAD_LINK: {
      return {
        ...state,
        uploadLinks: action.payload,
      };
    }

    case EReduxTypes.SET_CONVERT_DOCUMENT_DATA: {
      return {
        ...state,
        convertDocumentData: action.data,
      };
    }

    case EReduxTypes.MERGE_DOCUMENT: {
      return {
        ...state,
        convertDocumentData: action.data,
      };
    }

    case EReduxTypes.SET_PDF_FILE_CONTENT: {
      return {
        ...state,
        pdfFileContent: action?.data?.document,
      };
    }

    case EReduxTypes.UPLOAD_EDIT_DOCUMENT: {
      return {
        ...state,
        editDocumentData: action.payload,
        editFilename: action.payload?.filename?.replace('.pdf', ''),
      };
    }

    case EReduxTypes.UPDATE_EDIT_FILENAME: {
      // set new file name to localStorage
      const dataToEdit: any = getJsonFomStorage('dataToEdit');
      if (dataToEdit?.file) dataToEdit.file.filename = `${action.data?.filename}.pdf`;
      localStorage.setItem('dataToEdit', JSON.stringify(dataToEdit));

      return {
        ...state,
        editFilename: action.data?.filename,
      };
    }

    case EReduxTypes.UPDATE_EDIT_DOCUMENT_DATA: {
      return {
        ...state,
        editDocumentData: {
          ...state.editDocumentData,
          ...action.data,
        },
      };
    }

    case EReduxTypes.CONVERT_DOCUMENT: {
      return {
        ...state,
        fileUpload: action.data,
      };
    }

    case EReduxTypes.CONVERT_OPTIMIZE_IMAGE: {
      return {
        ...state,
        fileUpload: action.data,
      };
    }

    case EReduxTypes.UPDATE_DOWNLOAD_PROGRESS: {
      return {
        ...state,
        downloadProgress: action?.data?.progress,
      };
    }

    case EReduxTypes.DELETE_DOCUMENT_BY_ID: {
      const newDocuments = state?.documents?.data?.filter(
        (item) => item?.id !== action.data.documentId
      );
      return {
        ...state,
        documents: {
          data: newDocuments?.length ? [...newDocuments] : [],
          meta: {},
        },
      };
    }

    case EReduxTypes.GET_DOCUMENTS: {
      const data = (action?.payload?.files || [])?.sort(
        (a: any, b: any) => new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime()
      );
      return {
        ...state,
        documents: {
          data,
          meta: {},
        },
      };
    }

    case EReduxTypes.GET_DOCUMENT_BY_ID: {
      return {
        ...state,
        documentById: { ...action?.payload, id: action?.data?.documentId },
      };
    }

    case EReduxTypes.DOWNLOAD_DOCUMENT: {
      if (action?.payload?.url && action?.payload?.filename !== 'dummy-convert') {
        const link = document?.createElement('a');
        link.href = action?.payload?.url;
        link.setAttribute('target', '_blank');
        link.style.display = 'none';
        link.setAttribute('download', action?.payload?.filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      }
      return state;
    }

    case EReduxTypes.SET_MERGE_DOCUMENT_DATA: {
      return {
        ...state,
        mergeDocumentsList: action.data,
        sendNotification: false,
      };
    }

    case EReduxTypes.PUSH_MERGE_DOCUMENT_DATA: {
      if (state.mergeDocumentsList.length >= 15) {
        if (state.sendNotification) return state;
        return {
          ...state,
          sendNotification: true,
        };
      }

      return {
        ...state,
        mergeDocumentsList: [...state.mergeDocumentsList, action.data],
      };
    }

    case EReduxTypes.UPDATE_MERGE_DOCUMENT_IMAGE: {
      if (!action.data.thumbnail) return state;

      const newMergeDocumentsList = [...state.mergeDocumentsList];
      newMergeDocumentsList[action.data.index].thumbnail = action.data.thumbnail;

      return {
        ...state,
        mergeDocumentsList: newMergeDocumentsList,
      };
    }

    default:
      return state;
  }
};

export default documentsReducer;

import { userDataSelector } from 'data/selectors/user';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * Track user session time and provide to Customer.io attributes
 */
export const useSessionTimer = () => {
  const user = useSelector(userDataSelector);
  const [ sessionTime, setSessionTime ] = useState('');
  const minute = 60 * 1000;

  useEffect(() => {
    // Provide string values to customerIO analytic after 1 minute (A value), 2 minutes (B value), 10 minutes (C value)
    const sessionEventValues: Record<string, number> = {
      'A': 1, 'B': 5, 'C': 10
    };

    // Push values to CustomerIO only if user has email
    if (user.email && sessionTime) {
      const eventValue = sessionEventValues[sessionTime];
      window.analytics.identify(user?.id, {
        sessionTime: sessionTime
      });

      if (eventValue) {
        window.analytics.track('session', { time: eventValue });
      }
    }
  }, [sessionTime, user])

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    // Tracking user session time for CustomerIO after 1, 5 and 10 minutes
    const sessionTimer1 = setTimeout(() => {
      setSessionTime('A');
    }, minute);

    const sessionTimer2 = setTimeout(() => {
      setSessionTime('B');
    }, 5 * minute);

    const sessionTimer3 = setTimeout(() => {
      setSessionTime('C');
    }, 10 * minute);

    return () => {
      clearTimeout(sessionTimer1);
      clearTimeout(sessionTimer2);
      clearTimeout(sessionTimer3);
    };
  }, [minute, user]);
}


import { useFeature } from '@growthbook/growthbook-react';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { GrowthBookTestType, LocalStorageProperties } from 'ts/enums/growthbook';

export const useDescriptorSales = (): LocalStorageProperties => {
  const abTests = getJsonFomStorage('abTests');
  const descriptorTestABC = useFeature(GrowthBookTestType.DESCRIPTOR_SALES);

  if (abTests?.includes(LocalStorageProperties.DESCRIPTOR_SALES_B))
    return LocalStorageProperties.DESCRIPTOR_SALES_B;
  else if (abTests?.includes(LocalStorageProperties.DESCRIPTOR_SALES_C))
    return LocalStorageProperties.DESCRIPTOR_SALES_C;

  if (descriptorTestABC?.experiment?.key && descriptorTestABC?.experimentResult?.value) {
    return `${descriptorTestABC?.experiment?.key}_${descriptorTestABC?.experimentResult?.value}` as LocalStorageProperties;
  }

  return LocalStorageProperties.DESCRIPTOR_SALES_A;
};

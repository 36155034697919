import { FC } from 'react';

import { useBasePath } from 'hooks/useCustomNavigate';
import { LinkProps } from 'ts/types/link.props';
import { LinkContainer } from './styles';

const CommonLink: FC<LinkProps | any> = ({
  type = 'common',
  children,
  onClick,
  style,
  disabled,
  loading,
  size,
  to,
  removeLocale,
  ...props
}) => {
  const basePath = useBasePath();
  const route = removeLocale ? to : `${basePath}${to}`;

  return (
    <LinkContainer
      {...props}
      to={route}
      onClick={onClick}
      type={type}
      style={style}
      disabled={disabled || loading}
      size={size}
    >
      {children}
    </LinkContainer>
  );
};

export default CommonLink;

import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import closeImagePath from 'assets/close-icon.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Container, Content, Link } from './styles';
import { useLocation } from 'react-router-dom';

const CookiesBar: FC = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);
  const location = useLocation();
  const isVisible = location.pathname.includes('editor') ? 'false' : visible.toString();

  const handleClose = () => {
    localStorage.setItem('closeCookiesBar', 'true');
    setVisible(false);
  };

  const handleClickPrivacy = () => {
    window.scrollTo(0, 0);
  };

  if (localStorage.getItem('closeCookiesBar') === 'true') return null;

  return (
    <Container visible={isVisible}>
      <Content>
        <span>
          {t('global.cookies_bar_text')}{' '}
          <Link type="primary" to="/privacy-policy" onClick={handleClickPrivacy}>
            {t('global.privacy_policy_page_link')}
          </Link>
        </span>
        <LazyLoadImage src={closeImagePath} alt="close" onClick={handleClose} />
      </Content>
    </Container>
  );
};

export default CookiesBar;

import { useFeature } from '@growthbook/growthbook-react';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { GrowthBookTestType, LocalStorageProperties } from 'ts/enums/growthbook';

export const useDocxPreview = (): LocalStorageProperties => {
  const abTests = getJsonFomStorage('abTests');
  const docxPreviewAB = useFeature(GrowthBookTestType.DOCX_FILE_PREVIEW);

  if (abTests?.includes(LocalStorageProperties.DOCX_FILE_PREVIEW_B))
    return LocalStorageProperties.DOCX_FILE_PREVIEW_B;

  if (docxPreviewAB?.experiment?.key && docxPreviewAB?.experimentResult?.value) {
    return `${docxPreviewAB?.experiment?.key}_${docxPreviewAB?.experimentResult?.value}` as LocalStorageProperties;
  }

  return LocalStorageProperties.FILE_FOR_FREE_A;
};

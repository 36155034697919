import { CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const TestimonialsModalContainer = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 760px;
  display: flex;
  position: relative;
  overflow: hidden;
`;

export const Container = styled.div`
  padding: 20px 20px;
  gap: 10px;
  width: 50%;
  min-height: 495px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
    margin: 0 15px;
  }
`;

export const Title = styled(CommonTextH5)`
  color: var(--Dark-Dark, #111928);
  text-align: center;
`;

export const InputContainer = styled.div`
  width: 100%;
  position: relative;

  span {
    //styleName: Desktop/Body/Reg;

    font-size: 16px;
    font-weight: 400;
    line-height: 23.4px;
    text-align: left;
    color: #4c5e7f;
  }
`;

export const Description = styled.div`
  align-self: stretch;
  color: var(--Text-secondary, #4a4e62);
  text-align: center;
  /* Caption/Reg */

  font-size: 13.4px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 21.44px */

  a {
    color: var(--Primary-primary-50, #3758f9) !important;
    font-weight: 400;
    line-height: 160%;
    cursor: pointer;
    text-decoration: none;

    font-size: 13.4px;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  width: 125px;
  height: 22.694px;
  cursor: pointer;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

export const ContentWrapper = styled.div`
  padding: 0 20px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

export const LabeledDivider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;

  span {
    margin: 0 10px;
    color: var(--TailGrids-Text-Color, #637381);
    text-align: center;
    /* Body/Med */

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }

  &::before,
  &::after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background: #dfe4ea;
  }
`;

export const SignUpLink = styled.div`
  color: var(--Primary-primary-50, #3758f9);
  /* Body/Bold */

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  width: fit-content;
  display: inline-block;
  cursor: pointer;
`;

export const LoginLinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  span {
    color: #111928;
    /* Body/Reg */

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.4px;
    margin-right: 5px;
  }

  a {
    color: var(--Primary-primary-50, #3758f9);
    /* Body/Bold */

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.4px;
    cursor: pointer;
  }
`;

export const TesimonialBlockContainer = styled.div`
  display: flex;
  max-width: 50%;
  flex-grow: 1;
  padding: var(--Radius-s, 10px) var(--Radius-xl, 30px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--Radius-m, 15px);
  align-self: stretch;
  border-radius: var(--Radius-xxs, 0px);
  background: var(--Blue-10, #ebeeff);
`;

import { Button } from 'react-scroll';
import styled from 'styled-components';

export const SubmitButton = styled(Button)`
  display: flex;
  padding: 20px 64px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 4px solid var(--Primary-primary-50, #3758f9);
  background: var(--Primary-Color, #3758f9);
  color: var(--White-White, #fff);
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    background: var(--background-button-primary-hover, #2443d7);
    border: 4px solid var(--Primary-primary-50, #2443d7);
  }
`;

export const Title = styled.h2`
  color: var(--Colors-black-grey-white-main, #1d1d1d);
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
  line-height: 130%; /* 36.4px */
`;

export const FileInfoContainer = styled.div`
  display: flex;
  padding: var(--Radius-m, 15px) var(--Radius-s, 10px);
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: var(--Radius-xs, 5px);
  background: var(--20, #edf3ff);
  justify-content: space-between;

  span {
    color: var(--Text-color-subtitle, #4c5e7f);
    text-overflow: ellipsis;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    white-space: nowrap;
  }
`;

export const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  & > span {
    color: var(--Text-color-tittle, #212e45);

    /* Desktop/Body/Bold */
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
  }
`;

export const Container = styled.div`
  padding: 40px 30px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 30px;
`;

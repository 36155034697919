import { useFeature } from '@growthbook/growthbook-react';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { GrowthBookTestType, LocalStorageProperties } from 'ts/enums/growthbook';

export const useFileForFree = (): LocalStorageProperties => {
  const abTests = getJsonFomStorage('abTests');
  const yourFileForFreeAB = useFeature(GrowthBookTestType.FILE_FOR_FREE);

  if (abTests?.includes(LocalStorageProperties.FILE_FOR_FREE_B))
    return LocalStorageProperties.FILE_FOR_FREE_B;

  if (yourFileForFreeAB?.experiment?.key && yourFileForFreeAB?.experimentResult?.value) {
    return `${yourFileForFreeAB?.experiment?.key}_${yourFileForFreeAB?.experimentResult?.value}` as LocalStorageProperties;
  }

  return LocalStorageProperties.FILE_FOR_FREE_A;
};

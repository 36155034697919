import { Trans } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import authorImage from '../../assets/avatar.png';
import starIcon from '../../assets/five-star.svg';
import {
  AuthorBlock,
  Container,
  Name,
  NameAndPositionBlock,
  Position,
  TestimonialText,
} from './styles';

export const TestimonialBlock = () => {
  return (
    <Container>
      <LazyLoadImage src={starIcon} alt="rating" />
      <TestimonialText>
        <Trans i18nKey="testimonials_3_1.email_modal" />
      </TestimonialText>
      <AuthorBlock>
        <LazyLoadImage
          src={authorImage}
          alt="Author"
          width={48}
          height={48}
          style={{ aspectRatio: 1 }}
        />
        <NameAndPositionBlock>
          <Name>Jessica Lawson</Name>
          <Position>Accountant</Position>
        </NameAndPositionBlock>
      </AuthorBlock>
    </Container>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media (max-width: 888px) {
    padding: 0;
  }
`;

export const Title = styled.div`
  color: var(--Text-color-tittle, #212e45);
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 120%;
  margin-bottom: 50px;

  @media (max-width: 760px) {
    margin-bottom: 30px;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
  }
`;

export const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1360px;
`;

export const FaqItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 35px;

  @media (max-width: 950px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const FaqItemTitle = styled.div`
  color: var(--Text-color-tittle, #212e45);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 22px;
  flex: 1;

  @media (max-width: 950px) {
    max-width: 100%;
  }
`;

export const FaqItemDescription = styled.div`
  color: var(--Text-color-tittle, #212e45);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  flex: 1;

  /* Body/Reg */
  align-self: stretch;

  @media (max-width: 760px) {
    margin-bottom: 0;
  }
`;

export const Divider = styled.div`
  margin: 35px 0;
  width: 100%;
  background: var(--Blue-20, #BEC9FF);
  height: 1px;

  @media (max-width: 950px) {
    margin: 15px 0;
  }
`;

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { userDataSelector } from 'data/selectors/user';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getLoadingSelector } from 'data/selectors/loading';
import { Analytics } from 'services/analytics';

interface IGrowthBookOwnProvider {
  children: React.ReactNode;
}

export default function GrowthBookOwnProvider({ children }: IGrowthBookOwnProvider) {
  const user = useSelector(userDataSelector);
  const isLoading = useSelector(getLoadingSelector('get_user'));
  const abTestsRef = useRef<string[]>([]);

  const isMobile =
    'maxTouchPoints' in navigator &&
    navigator.maxTouchPoints > 0 &&
    document.body.clientWidth < 600;

  const storeExperimentsValues = useCallback(
    (experimentId: string, variationId: string) => {
      if (abTestsRef.current.find((item) => item.includes(experimentId))) return;
      const newAbTests = [...abTestsRef.current, `${experimentId}_${variationId}`];
      abTestsRef.current = newAbTests;

      // check if the growthbook experiment is enabled
      if (growthBook.isOn(experimentId)) {
        localStorage.setItem('abTests', JSON.stringify(newAbTests));
      }

      setTimeout(() => {
        Analytics.updateUser({
          key: 'ab_test',
          value: newAbTests,
        });
      }, 0);
    },
    [abTestsRef] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const growthBook = useMemo(
    () =>
      new GrowthBook({
        apiHost: 'https://cdn.growthbook.io',
        clientKey: process.env.REACT_APP_GROWTHBOOK_KEY,
        enableDevMode: true,
        subscribeToChanges: true,
        trackingCallback: (experiment, result) => {
          storeExperimentsValues(experiment.key, result.value);
        },
      }),
    [storeExperimentsValues]
  );

  useEffect(() => {
    if (isLoading || !user) return;

    abTestsRef.current = localStorage.getItem('abTests')
      ? JSON.parse(localStorage.getItem('abTests') as string)
      : [];

    growthBook.init({ streaming: true });

    growthBook.setAttributes({
      id: user?.id,
      isMobile: isMobile,
    });
  }, [growthBook, isLoading, user, isMobile]);

  return <GrowthBookProvider growthbook={growthBook}>{children}</GrowthBookProvider>;
}

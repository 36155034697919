import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: var(--Radius-xl, 30px) var(--Radius-l, 20px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Radius-l, 10px);
  align-self: stretch;
  border-radius: var(--Radius-s, 10px);
  background: #f5f7ff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
`;

export const TestimonialText = styled.p`
  color: var(--Text-color-tittle, #212e45);
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
`;

export const AuthorBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin-top: 10px;
`;

export const NameAndPositionBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
`;

export const Name = styled.p`
  color: var(--Text-color-tittle, #212e45);
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
`;

export const Position = styled.p`
  color: var(--Text-color-subtitle, #4c5e7f);
  font-size: 13px;
  font-weight: 400;
  line-height: 110%;
`;

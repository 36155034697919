export const servicesListWithGroups = {
  convertFromPDF: [
    {
      path: '/pdf-to-word',
      name: 'landing.pdf_to_word.name',
    },
    {
      path: '/pdf-to-png',
      name: 'landing.pdf_to_png.name',
    },
    {
      path: '/pdf-to-jpg',
      name: 'landing.pdf_to_jpg.name',
    },
    {
      path: '/pdf-to-epub',
      name: 'landing.pdf_to_epub.name',
    },
    {
      path: '/pdf-to-excel',
      name: 'landing.pdf_to_excel.name',
    },
    {
      path: '/pdf-to-pptx',
      name: 'landing.pdf_to_pptx.name',
    },
    {
      path: '/pdf-to-rtf',
      name: 'landing.pdf_to_rtf.name',
    },
  ],
  convertToPDF: [
    {
      path: '/word-to-pdf',
      name: 'landing.word_to_pdf.name',
    },
    {
      path: '/png-to-pdf',
      name: 'landing.png_to_pdf.name',
    },
    {
      path: '/jpg-to-pdf',
      name: 'landing.jpg_to_pdf.name',
    },
    {
      path: '/epub-to-pdf',
      name: 'landing.epub_to_pdf.name',
    },
    {
      path: '/excel-to-pdf',
      name: 'landing.excel_to_pdf.name',
    },
    {
      path: '/pptx-to-pdf',
      name: 'landing.pptx_to_pdf.name',
    },
    {
      path: '/rtf-to-pdf',
      name: 'landing.rtf_to_pdf.name',
    },
  ],
  editPdf: [
    {
      path: '/edit-pdf',
      name: 'landing.edit_pdf.name',
    },
    {
      path: '/sign-pdf',
      name: 'landing.sign_pdf.name',
    },
    {
      path: '/delete-pages-pdf',
      name: 'landing.delete_pages_pdf.name',
    },
    {
      path: '/merge-pdf',
      name: 'landing.merge_pdf.name',
    },
    {
      path: '/merge-images',
      name: 'landing.merge_images.name',
    },
    {
      path: '/split-pdf',
      name: 'landing.split_pdf.name',
    },
    {
      path: '/compress-pdf',
      name: 'landing.compress_pdf.name',
    },
    {
      path: '/compress-images',
      name: 'landing.compress_images.name',
    },
    {
      path: '/ocr-pdf',
      name: 'landing.pdf_ocr.name',
    },
  ],
  pdfActions: [
    {
      path: '/edit-pdf',
      name: 'landing.edit_pdf.name',
    },
    {
      path: '/sign-pdf',
      name: 'landing.sign_pdf.name',
    },
    {
      path: '/delete-pages-pdf',
      name: 'landing.delete_pages_pdf.name',
    },
    { path: '/ocr-pdf', name: 'landing.pdf_ocr.name' },
    { path: '/image-to-text', name: 'landing.image_to_text.name' },
  ],
  forms: [
    {
      path: '/form-w9',
      name: 'landing.form_w9.name',
    },
    {
      path: '/form-ds11',
      name: 'landing.form_ds11.name',
    },
    {
      path: '/form-ds82',
      name: 'landing.form_ds82.name',
    },
    {
      path: '/form-cms-1500',
      name: 'landing.form_cms_1500.name',
    },
    {
      path: '/form-i9',
      name: 'landing.form_i9.name',
    },
    {
      path: '/form-i864',
      name: 'landing.form_i864.name',
    },
    {
      path: '/form-w4',
      name: 'landing.form_w4.name',
    },
  ],
};

import { additionalLanguages, SupportedLanguage } from 'i18n';
import { Amplitude } from 'services/analytics/amplitude';
import { AnalyticsEvent } from 'services/analytics/events';

const defaultPaths = [
  '/',
  'choose-plan',
  'checkout',
  'checkout/success',
  'app/dashboard',
  'app/settings',
  'edit-pdf',
  'editor',
  'fill-pdf',
  'sign-pdf',
  'compress-pdf',
  'compress-images',
  'delete-pages-pdf',
  'pdf-converter',
  'merge-pdf',
  'merge-images',
  'complete-merge',
  'split-pdf',
  'contact-us',
  'about-us',
  'ocr-pdf',
  'image-to-text',
  'app/subscription-cancel-confirmation',
  'app/subscription-offer',
  'privacy-policy',
  'subscription-terms',
  'terms',
  'money-back-policy',
  'form-w9',
  'form-ds11',
  'form-ds82',
];

export const Analytics = {
  sendEvent: async (event: AnalyticsEvent) => {
    Amplitude.track(event);
  },
  updateUser: (data: AnalyticsUserProp | AnalyticsUserProp[]) => {
    Amplitude.updateUser(data);
  },
  setUserId: (userId: string) => {
    Amplitude.setUserId(userId);
  },
  getCurrentPageName: () => {
    const path = window.location.pathname;
    const woSlash = path.replace('/', '');

    const locales = additionalLanguages;

    const urlFirstSegment = woSlash.split('/')[0];

    const selectedLocale = locales.includes(urlFirstSegment as Exclude<SupportedLanguage, 'en'>)
      ? urlFirstSegment
      : null;

    const woLocales = selectedLocale ? `${woSlash.slice(3)}` : woSlash;

    // converters
    if (path.includes('-to-')) {
      return woLocales.replace(/-/g, '_');
    }

    if (defaultPaths.includes(woLocales)) {
      return woLocales;
    }

    if (window.location.pathname.includes('reset-password')) {
      return 'restore';
    }

    switch (window.location.pathname) {
      case '/':
        return 'main_page';
      case '/fr':
      case '/fr/':
        return '/fr/main_page';
      case '/de':
      case '/de/':
        return '/de/main_page';
      case '/it':
      case '/it/':
        return '/it/main_page';
      case '/ja':
      case '/ja/':
        return '/ja/main_page';
      case '/es':
      case '/es/':
        return '/es/main_page';
      case '/nl':
      case '/nl/':
        return '/nl/main_page';
      case '/pt':
      case '/pt/':
        return '/pt/main_page';
      case '/ko':
      case '/ko/':
        return '/ko/main_page';
      case '/el':
      case '/el/':
        return '/el/main_page';
      // case '/pl':
      // case '/pl/':
      //   return '/pl/main_page';
      // case '/tr':
      // case '/tr/':
      //   return '/tr/main_page';
      default:
        return '404';
    }
  },
  getCurrentPageLocale: () => {
    const path = window.location.pathname;
    const woSlash = path.replace('/', '');

    const locales = additionalLanguages;

    const urlFirstSegment = woSlash.split('/')[0];

    const selectedLocale = locales.includes(urlFirstSegment as Exclude<SupportedLanguage, 'en'>)
      ? urlFirstSegment
      : null;

    const woLocales = selectedLocale ? `${woSlash.slice(3)}` : woSlash;

    // converters
    if (path.includes('-to-')) {
      return woSlash.replace(/-/g, '_');
    }

    if (defaultPaths.includes(woLocales)) {
      return woSlash;
    }

    if (window.location.pathname.includes('reset-password')) {
      return 'restore';
    }

    switch (window.location.pathname) {
      case '/':
        return 'main_page';
      case '/fr':
      case '/fr/':
        return '/fr/main_page';
      case '/de':
      case '/de/':
        return '/de/main_page';
      case '/it':
      case '/it/':
        return '/it/main_page';
      case '/ja':
      case '/ja/':
        return '/ja/main_page';
      case '/es':
      case '/es/':
        return '/es/main_page';
      case '/nl':
      case '/nl/':
        return '/nl/main_page';
      case '/pt':
      case '/pt/':
        return '/pt/main_page';
      case '/ko':
      case '/ko/':
        return '/ko/main_page';
      case '/pl':
      case '/pl/':
        return '/pl/main_page';
      case '/tr':
      case '/tr/':
        return '/tr/main_page';
      case '/el':
      case '/el/':
        return '/el/main_page';
      default:
        return '404';
    }
  },
};

export interface AnalyticsService {
  init: () => void;
  track: (event: AnalyticsEvent) => void;
  updateUser: (data: AnalyticsUserProp | AnalyticsUserProp[]) => void;
  setUserId: (userId: string) => void;
}

export interface AnalyticsUserProp {
  key: string;
  value: string | number | boolean | string[];
}

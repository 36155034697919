import { useFeature } from '@growthbook/growthbook-react';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { GrowthBookTestType, LocalStorageProperties } from 'ts/enums/growthbook';

export const useTestimonials = (): LocalStorageProperties => {
  const abTests = getJsonFomStorage('abTests');
  const testimonialsAB = useFeature(GrowthBookTestType.TESTIMONIALS);

  if (abTests?.includes(LocalStorageProperties.TESTIMONIALS_B))
    return LocalStorageProperties.TESTIMONIALS_B;

  if (testimonialsAB?.experiment?.key && testimonialsAB?.experimentResult?.value) {
    return `${testimonialsAB?.experiment?.key}_${testimonialsAB?.experimentResult?.value}` as LocalStorageProperties;
  }

  return LocalStorageProperties.TESTIMONIALS_A;
};
